import { appendZToDateString } from '@energy-stacks/shared';
import { VehicleDetailsDto } from '../vehicleDetailsDto';
import { ExceptionalHours, VehicleDetailsModel } from '../vehicleDetailsModel';
import { ExceptionalHoursDto } from '@energy-stacks/fleet/shared';

export const vehicleDetailsNormalizer = (
  vehicleDetails: VehicleDetailsDto
): VehicleDetailsModel => {
  return {
    vehicleId: vehicleDetails.vehicleIdentificationNumber,
    plantId: vehicleDetails.depotId,
    name: vehicleDetails.name,
    licencePlate: vehicleDetails.licensePlateNumber,
    status: vehicleDetails.status,
    brand: vehicleDetails.brandName,
    model: vehicleDetails.model,
    variant: vehicleDetails.variant,
    // Setting default values for type and group because values are mandatory, but vehicles created before introduction of those fields wont have them in the database
    type: vehicleDetails.ownershipType || 'internal',
    group: vehicleDetails.usagePriority || 'primary',
    hasUVFilter: vehicleDetails.skippingPenaltyRules || false,
    note: vehicleDetails.notes || '',
    workingHours: {
      regular:
        vehicleDetails.workingHours.regular_hours?.map((day) => ({
          weekday: day.weekday,
          startTime: day.period_begin,
          endTime: day.period_end,
        })) ?? [],
      exceptional: [
        ...exceptionalHoursNormalizer(
          vehicleDetails.workingHours.exceptional_openings ?? [],
          true
        ),
        ...exceptionalHoursNormalizer(
          vehicleDetails.workingHours.exceptional_closings ?? [],
          false
        ),
      ],
    },
  };
};

const exceptionalHoursNormalizer = (
  exceptionalHours: ExceptionalHoursDto[],
  isOperating: boolean
): ExceptionalHours[] => {
  return exceptionalHours.map((e) => ({
    isOperating,
    date: appendZToDateString(e.period_begin),
    startDate: isOperating
      ? appendZToDateString(e.period_begin)
      : e.period_begin, // Temporary, until we resolve the time zone issue
    endDate: appendZToDateString(e.period_end),
  }));
};
