import { Row, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  ESTableWrapper,
  ESTableFilters,
  useElementScrollRestoration,
  useESTableServer,
  ESTable,
  ESTableHead,
  ESTableBody,
  ESTablePagination,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@energy-stacks/core/ui';
import {
  ESTooltip,
  NoTableData,
  TableSearchField,
  TableColumnSelect,
} from '@energy-stacks/shared';
import { Box, Stack, Typography } from '@mui/material';
import {
  BusinessAccountModel,
  BusinessAccountsPageModel,
} from '@energy-stacks/fleet/feature-business-accounts-data';
import { TanksCountCell } from './TanksCountCell';
import { useNavigate } from 'react-router-dom';
import { useRemovePlantIdPrefix } from './useRemovePlantIdPrefix';
import { useCallback } from 'react';
import { FleetRoutes, ServerSideTableProps } from '@energy-stacks/fleet/shared';

interface BusinessAccountsTableProps
  extends ServerSideTableProps<BusinessAccountModel> {
  tableId?: string;
  businessAccounts: BusinessAccountsPageModel;
}

const TABLE_SCROLL_RESTORATION_ID = 'businessAccountsTable';

export const BusinessAccountsTable: React.FC<BusinessAccountsTableProps> = ({
  tableId = 'businessAccounts',
  businessAccounts,
  pagination,
  onPaginationChange,
  search,
  setSearch,
  sorting,
  onSortingChange,
  isFetching,
}) => {
  const [t] = useTranslation('businessAccounts');
  const columnHelper = createColumnHelper<BusinessAccountModel>();
  const navigate = useNavigate();
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columns = [
    columnHelper.accessor((row) => removePlantIdPrefix(row.id), {
      id: 'id',
      sortingFn: 'alphanumeric',
      header: () => t('accountNumber'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">
          {removePlantIdPrefix(info.getValue())}
        </Typography>
      ),
      enableGlobalFilter: true,
      size: 100,
    }),
    columnHelper.accessor('accountType', {
      sortingFn: 'alphanumeric',
      header: () => t('accountType'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">{info.getValue()}</Typography>
      ),
      enableGlobalFilter: true,
      size: 140,
      enableSorting: false,
    }),
    columnHelper.accessor('companyName', {
      sortingFn: 'alphanumeric',
      header: () => t('accountName'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction="column">
          <Typography>{info.row.original.phoneNumber}</Typography>
          <Typography>{info.getValue()}</Typography>
        </Stack>
      ),
      enableGlobalFilter: true,
      size: 140,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) => `${row.address.city} ${row.address.street}`,
      {
        id: 'address',
        sortingFn: 'alphanumeric',
        header: () => t('address'),
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          return (
            <Stack direction="column">
              <Typography>{row.original.address.city || '---'}</Typography>
              <Typography>{row.original.address.street || '---'}</Typography>
            </Stack>
          );
        },
        enableGlobalFilter: true,
        size: 140,
        enableSorting: false,
      }
    ),
    columnHelper.accessor('locations', {
      header: () => t('numberOfTanks'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        return (
          <TanksCountCell
            rawCount={row.original.locations.raw}
            degassedCount={row.original.locations.degassed}
          />
        );
      },
      enableGlobalFilter: false,
      size: 140,
      enableSorting: false,
    }),
  ];

  const { instance, rows } = useESTableServer(
    businessAccounts.businessAccounts,
    columns,
    {
      tableId,
      state: {
        pagination,
        sorting,
        globalFilter: search,
      },
      onSortingChange,
      onPaginationChange,
      enableColumnResizing: true,
    }
  );

  const scrollCache = useElementScrollRestoration({
    id: TABLE_SCROLL_RESTORATION_ID,
  });

  const handleRowClick = useCallback(
    (row: Row<BusinessAccountModel>) =>
      navigate(`${FleetRoutes.BusinessAccounts}/${row.original.id}`, {
        state: { from: FleetRoutes.BusinessAccounts },
      }),
    [navigate]
  );

  return (
    <>
      <ESTableFilters>
        <ESTooltip title={t('searchBusinessAccountPlaceholder')}>
          <TableSearchField
            placeholder={t('searchBusinessAccountPlaceholder')}
            value={search}
            onChange={setSearch}
            tableInstance={instance}
          />
        </ESTooltip>
        <Box sx={{ marginLeft: 'auto' }}>
          <TableColumnSelect instance={instance} />
        </Box>
      </ESTableFilters>
      <ESTableWrapper>
        <ESTable
          instance={instance}
          scrollRestorationId={TABLE_SCROLL_RESTORATION_ID}
          initialScrollTop={scrollCache.scrollY}
        >
          <ESTableHead showProgress={isFetching} instance={instance} />
          <ESTableBody
            table={instance}
            rows={rows}
            onRowClick={handleRowClick}
          />
        </ESTable>

        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoJobs')} />
        ) : null}
        <ESTablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          instance={instance}
          count={businessAccounts?.totalElements || rows.length}
        />
      </ESTableWrapper>
    </>
  );
};
