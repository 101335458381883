import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  ESTableWrapper,
  ESTableFilters,
  useESTableBasic,
  ESTableBasicConfig,
  ESPopover,
  useElementScrollRestoration,
  ESVirtualizedTable,
} from '@energy-stacks/core/ui';
import {
  NoTableData,
  TableSearchField,
  ESTooltip,
  TableColumnFilter,
  ClearFiltersButton,
  TableColumnSelect,
  TableDateRangeFilter,
  TableRowsCount,
} from '@energy-stacks/shared';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  vehicleGroups,
  VehicleModel,
  vehicleStatusFilterOptions,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { IconExclamationCircle } from '@tabler/icons-react';
import { VehicleStatusChip } from './VehicleStatusChip';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { VehicleLogo } from './VehicleLogo';
import { VehiclesTableMoreOptionsMenu } from './VehiclesTableMoreOptionsMenu';
import { TableVehicleFilter } from './TableVehicleFilter';
import { useCallback } from 'react';
import { usePersistedVehicleFilter } from './usePersistedVehicleFiter';

interface VehiclesTableProps extends ESTableBasicConfig<VehicleModel> {
  vehicles: VehicleModel[];
  enableColumnSelection?: boolean;
  enableTimeRangeFilter?: boolean;
  onDateRangeChange?: (dateRange: DateRange) => void;
  defaultDateRange: DateRange;
  dateRange: DateRange;
  right?: React.ReactNode;
  scrollRestorationId?: string;
}

const ROW_HEIGHT = 68.5;

export const VehiclesTable: React.FC<VehiclesTableProps> = ({
  tableId = 'vehicles',
  vehicles,
  hiddenColumnsIds = [],
  defaultRowSelection,
  enableColumnSelection,
  enableTimeRangeFilter,
  enableRowSelection,
  onSelectionChange,
  onDateRangeChange,
  dateRange,
  defaultDateRange,
  right,
  scrollRestorationId,
}) => {
  const [t] = useTranslation('vehicles');
  const [tShared] = useTranslation('shared');
  const [tFleetShared] = useTranslation('fleetShared');
  const { palette, spacing } = useTheme();
  const columnHelper = createColumnHelper<VehicleModel>();

  const columns = [
    columnHelper.accessor('brandAndModel', {
      header: () => t('brandAndModel'),
      footer: (props) => props.column.id,
      cell: ({ cell, row }) => (
        <Stack direction="row" gap={3} alignItems="center">
          <VehicleLogo brandId={row.original.brandId} />
          <Typography variant="inherit">{cell.getValue()}</Typography>
        </Stack>
      ),
      enableGlobalFilter: true,
      size: 160,
      minSize: 130,
    }),
    columnHelper.accessor('vehicleId', {
      header: () => t('name'),
      footer: (props) => props.column.id,
      cell: ({ row }) => (
        <Typography variant="inherit">
          {row.original.name.toUpperCase()}
        </Typography>
      ),
      enableGlobalFilter: true,
      filterFn: (row, _, filterValue) => {
        if (!filterValue || filterValue?.length === 0) {
          return true;
        }
        return filterValue.includes(row.original.vehicleId);
      },
      size: 160,
    }),
    columnHelper.accessor('licencePlate', {
      header: () => t('licencePlate'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Typography variant="inherit">
          {info.getValue().toUpperCase()}
        </Typography>
      ),
      enableGlobalFilter: true,
      size: 160,
      minSize: 120,
    }),
    columnHelper.accessor((row) => `${row.weight} ${row.volume}`, {
      id: 'weight',
      header: () => t('capacity'),
      footer: (props) => props.column.id,
      size: 140,
      cell: ({ row }) => {
        const weight = row.original.weight;
        const volume = row.original.volume;
        return (
          <Stack direction="column">
            {typeof weight === 'number' ? (
              <Typography variant="inherit">
                {weight.toLocaleString()} KG
              </Typography>
            ) : null}
            {typeof volume === 'number' ? (
              <Typography variant="inherit">
                {volume.toLocaleString()} m&sup3;
              </Typography>
            ) : null}
          </Stack>
        );
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('status', {
      header: () => t('status'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack flexDirection="row" alignItems="center">
          <VehicleStatusChip status={info.getValue()} />
          {info.row.original.isPartiallyPlanned && (
            <ESPopover
              content={t('partiallyPlannedTooltipText')}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <IconExclamationCircle
                color={palette.grey[500]}
                style={{
                  marginLeft: spacing(3),
                  width: spacing(5.5),
                }}
              />
            </ESPopover>
          )}
        </Stack>
      ),
      enableGlobalFilter: false,
      enableSorting: false,
      size: 120,
      filterFn: 'arrIncludesSome',
    }),
    columnHelper.accessor('group', {
      id: 'group',
      header: () => t('vehicleGroup'),
      cell: (info) => (
        <Typography>{t(`vehicleGroups.${info.getValue()}`)}</Typography>
      ),
      size: 80,
      enableGlobalFilter: false,
      enableSorting: false,
      filterFn: 'arrIncludesSome',
    }),
    columnHelper.accessor('note', {
      id: 'note',
      header: () => t('note'),
      cell: (info) => (
        <ESPopover content={info.getValue()} breakWord>
          <Typography>{info.getValue()}</Typography>
        </ESPopover>
      ),
      size: 150,
      enableGlobalFilter: false,
    }),
    columnHelper.display({
      id: 'actions',
      meta: { isActionable: true },
      size: 20,
      header: () => tFleetShared('actionsColumnLabel'),
      cell: ({ row }) => (
        <VehiclesTableMoreOptionsMenu vehicleId={row.original.vehicleId} />
      ),
      enableResizing: false,
    }),
  ];

  const [persistedVehicleFilter, setPersistedVehicleFilter] =
    usePersistedVehicleFilter(tableId);

  const onVehicleFilterChange = useCallback(
    (value: string[]) => {
      setPersistedVehicleFilter(value);
    },
    [setPersistedVehicleFilter]
  );

  const {
    instance,
    rows,
    globalFilter,
    onGlobalFilterChange,
    handleSelectRow,
  } = useESTableBasic(vehicles, columns, {
    tableId,
    fitRowHeight: ROW_HEIGHT,
    defaultRowSelection,
    enableRowSelection,
    onSelectionChange,
    getRowId: (row) => row.vehicleId,
    hiddenColumnsIds,
    manualPagination: true,
    enableColumnResizing: true,
    initialColumnFilters: persistedVehicleFilter.length
      ? [
          { id: 'vehicleId', value: persistedVehicleFilter },
          { id: 'group', value: ['primary'] },
        ]
      : [{ id: 'group', value: ['primary'] }],
  });

  const scrollCache = useElementScrollRestoration(
    scrollRestorationId
      ? {
          id: scrollRestorationId,
        }
      : undefined
  );

  return (
    <>
      <ESTableFilters>
        <ESTooltip title={t('searchVehiclesPlaceholder')}>
          <TableSearchField
            placeholder={t('searchVehiclesPlaceholder')}
            value={globalFilter}
            onChange={onGlobalFilterChange}
            tableInstance={instance}
          />
        </ESTooltip>
        {enableTimeRangeFilter && (
          <TableDateRangeFilter
            title={tShared('timeRange')}
            isActive
            defaultDateRange={defaultDateRange}
            dateRange={dateRange}
            onDateRangeApplied={(dateRange) => {
              onDateRangeChange?.(dateRange);
            }}
            onDateRangeCleared={() => {
              onDateRangeChange?.(defaultDateRange);
            }}
            weekStartsOn={1}
          />
        )}
        <TableColumnFilter
          column={instance.getColumn('status')}
          columnLabel={t('status')}
          options={vehicleStatusFilterOptions.map((v) => ({
            label: t(`vehicleStatuses.${v}`),
            value: v,
          }))}
          isClearable
        />
        <TableColumnFilter
          column={instance.getColumn('group')}
          columnLabel={t('vehicleGroup')}
          options={vehicleGroups.map((v) => ({
            label: t(`vehicleGroups.${v}`),
            value: v,
          }))}
          isClearable
        />
        <TableVehicleFilter
          column={instance.getColumn('vehicleId')}
          onApplyFilter={onVehicleFilterChange}
        />
        <ClearFiltersButton
          tableInstance={instance}
          onFiltersCleared={() => {
            onVehicleFilterChange([]);
          }}
        />
        {enableColumnSelection ? (
          <Box sx={{ marginLeft: 'auto' }}>
            <TableColumnSelect instance={instance} />
          </Box>
        ) : null}
        {right ? right : null}
      </ESTableFilters>
      <ESTableWrapper>
        <ESVirtualizedTable
          instance={instance}
          rows={rows}
          rowHeight={ROW_HEIGHT}
          onRowClick={handleSelectRow}
          scrollRestorationId={scrollRestorationId}
          initialScrollTop={scrollCache.scrollY}
        />
        {rows.length === 0 ? (
          <NoTableData message={t('thereAreNoVehicles')} />
        ) : null}
        <TableRowsCount
          instance={instance}
          totalCountLabel={t('totalRowsCount')}
        />
      </ESTableWrapper>
    </>
  );
};
