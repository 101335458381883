import { useTranslation } from 'react-i18next';
import { ESTooltip } from '@energy-stacks/shared';
import { Link } from 'react-router-dom';
import {
  FleetRoutes,
  JobStatusChip,
  JobStatusChipProps,
} from '@energy-stacks/fleet/shared';

interface JobsTableJobStatusChipProps extends JobStatusChipProps {
  tourId?: string;
}

export const JobsTableJobStatusChip: React.FC<JobsTableJobStatusChipProps> = ({
  tourId,
  status,
}) => {
  const [t] = useTranslation('fleetShared');

  if (!tourId) {
    return <JobStatusChip status={status} isActionable={false} />;
  }

  return (
    <ESTooltip title={t('jobStatusChipTooltipText')}>
      <Link to={`${FleetRoutes.Tours}/${tourId}`}>
        <JobStatusChip status={status} isActionable={true} />
      </Link>
    </ESTooltip>
  );
};
