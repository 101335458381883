import { useESSnackbar } from '@energy-stacks/core/ui';
import {
  VehicleModel,
  useDeleteVehicleMutation,
  useGetVehicleDetailsQuery,
} from '@energy-stacks/fleet/feature-vehicles-data';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { ConfirmDeleteDialog, useAppLocation } from '@energy-stacks/shared';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { vehiclesApiErrors } from './vehiclesApiErrors';

export const DeleteVehicle = () => {
  const [t] = useTranslation('vehicles');
  const navigate = useNavigate();
  const location = useAppLocation();
  const [deleteVehicle] = useDeleteVehicleMutation();
  const { showSnackbar } = useESSnackbar();
  const { vehicleId } = useParams<{ vehicleId: VehicleModel['vehicleId'] }>();
  const {
    data: vehicle,
    isLoading,
    isError,
    error,
  } = useGetVehicleDetailsQuery(vehicleId ?? '');

  const handleCloseDialog = useCallback(() => {
    setTimeout(() => {
      if (location.state?.background) {
        navigate(-1);
      } else {
        navigate(FleetRoutes.Vehicles);
      }
    });
  }, [location, navigate]);

  const handleConfirm = useCallback(() => {
    if (!vehicleId) return;
    return deleteVehicle(vehicleId)
      .unwrap()
      .then(() => {
        showSnackbar(
          'success',
          'responseMessages.successfulDelete',
          'vehicles'
        );
        handleCloseDialog();
      })
      .catch((error) => {
        const errorCode = vehiclesApiErrors[error.data?.errorCode];
        showSnackbar(
          'error',
          errorCode ? `responseMessages.${errorCode}` : undefined,
          'vehicles'
        );
      });
  }, [vehicleId, deleteVehicle, showSnackbar, handleCloseDialog]);

  useEffect(() => {
    if (isError) {
      showSnackbar('error', undefined, 'vehicles');
      handleCloseDialog();
    }
  }, [isError, error, showSnackbar, handleCloseDialog]);
  const isAssigned = vehicle?.status === 'PLANNED';
  const descriptionKey = isAssigned
    ? 'deleteVehicleDescription.assignedMessage'
    : 'deleteVehicleDescription.confirmMessage';

  if (!vehicle) return null;

  return (
    <ConfirmDeleteDialog
      title={t('deleteVehicleConfirmTitle')}
      description={t(descriptionKey)}
      onCancel={handleCloseDialog}
      onConfirm={handleConfirm}
      fullWidth
      disabled={isAssigned || isLoading || isError}
    />
  );
};
