import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { TourDndTableHeader } from './TourDndTableHeader';
import { TourInfoStartDateButton } from './TourInfoStartDateButton';
import { TourDetailsVehicleChip } from './TourDetailsVehicleChip';
import { useEditTourDetailsContext } from '../edit-tour/useEditTourDetailsContext';

type TourJobsTableHeaderProps = {
  vehicleName: TourModel['vehicle']['name'];
  vehicleIndex?: number;
  startDate: TourModel['startDate'];
};

export const TourJobsTableHeader: React.FC<TourJobsTableHeaderProps> = ({
  vehicleName,
  vehicleIndex,
  startDate,
}) => {
  const { isTourChanged } = useEditTourDetailsContext();

  return (
    <TourDndTableHeader>
      <TourDetailsVehicleChip title={vehicleName} index={vehicleIndex ?? 0} />
      <TourInfoStartDateButton
        startDate={startDate}
        shouldPromptBeforeNavigate={isTourChanged}
      />
    </TourDndTableHeader>
  );
};
