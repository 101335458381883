export const vehiclesApiErrors: Record<string, string> = {
  FLEET_VEHICLE_IDENTIFICATION_NUMBER_NOT_FOUND:
    'fleetVehicleIdentificationNumberNotFound',
  VEHICLE_CORE_UUID_NOT_FOUND: 'vehicleCoreUuidNotFound',
  VEHICLE_IS_ASSIGNED_TO_A_TOUR: 'vehicleIsAssignedToATour',
  VEHICLES_CORE_NOT_FOUND: 'vehicleCoreNotFound',
  BUSINESS_ACCOUNT_WITHOUT_LOCATIONS: 'businessAccountWithoutLocations',
  BUSINESS_ACCOUNT_LOCATIONS_WITHOUT_GEOLOCATION:
    'businessAccountLocationsWithoutGeolocation',
  INVALID_BEGIN_END_TIME: 'invalidBeginEndTime',
  INVALID_EXCEPTIONAL_PERIOD_BEGIN_END_DATE:
    'invalidExceptionalPeriodBeginEndDate',
  MORE_THAN_ONE_EXCEPTIONAL_PERIOD_WITHIN_SINGLE_DATE:
    'moreThanOneExceptionalPeriodWithinSingleDate',
  EXCEPTIONAL_PERIOD_DATES_OVERLAP: 'exceptionalPeriodDatesOverlap',
  NEW_EXCEPTIONAL_CLOSING_CANNOT_BLOCK_ACTIVE_TOURS:
    'newExceptionalClosingCannotBlockActiveTours',
  NEW_EXCEPTIONAL_OPENING_NOT_EQUAL_TO_OR_EXTENDING_EXISTING:
    'newExceptionalOpeningNotEqualToOrExtendingExisting',
  INVALID_VEHICLE_BUSINESS_ACCOUNT: 'invalidVehicleBusinessAccount',
};
