import {
  ESPageLoadingIndicator,
  RefetchOnError,
  toPayloadDate,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { ToursTable } from './ToursTable';
import { Outlet } from 'react-router-dom';
import { FleetPage } from '@energy-stacks/fleet/shared';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { endOfDay, startOfDay } from 'date-fns';
import {
  useESTableServerFilterControls,
  useESTableServerControls,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@energy-stacks/core/ui';
import {
  TourModel,
  ToursTableSortOrderModel,
  useGetPaginatedToursQuery,
} from '@energy-stacks/fleet/feature-tours-data';

const defaultToursDateRangeFilterValue: DateRange = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};

export type TourFilterModel = Pick<
  TourModel,
  'status' | 'vehicle' | 'date' | 'isViolatingContaminationRules'
>;

export const ToursPage = () => {
  const [t] = useTranslation('tours');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);

  const { filtersState, setFilters, tableColumnFilters } =
    useESTableServerFilterControls<TourFilterModel>({
      defaultFilters: [{ id: 'date', value: defaultToursDateRangeFilterValue }],
      shouldPersist: true,
    });

  const { pagination, setPagination, search, setSearch, sorting, setSorting } =
    useESTableServerControls({
      defaultRowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    });

  const { data, isError, isFetching, isLoading, refetch } =
    useGetPaginatedToursQuery(
      {
        searchValue: search || undefined,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sort:
          sorting.order && sorting.column
            ? ({
                id: sorting.column,
                order: sorting.order,
              } as ToursTableSortOrderModel)
            : undefined,
        startDateFrom: filtersState.date?.startDate
          ? toPayloadDate(filtersState.date.startDate)
          : undefined,
        endDateTo: filtersState.date?.endDate
          ? toPayloadDate(filtersState.date.endDate)
          : undefined,
        statuses: filtersState.status,
        vehicleIdentificationNumbers: filtersState.vehicle,
        hasPenaltyRuleViolation: filtersState.isViolatingContaminationRules,
      },
      { skip: Boolean(search && search.length < 3) }
    );

  return (
    <FleetPage title={pageTitle}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data && !isError ? (
        <ToursTable
          tours={data}
          enableColumnSelection
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting.state}
          onSortingChange={setSorting}
          search={search}
          setSearch={setSearch}
          isFetching={isFetching}
          setFilters={setFilters}
          tableColumnFilters={tableColumnFilters}
        />
      ) : null}
      <Outlet />
    </FleetPage>
  );
};
