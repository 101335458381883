import { Stack } from '@mui/material';
import { TourDetailsVehicleFilter } from './TourDetailsVehicleFilter';
import { useCallback, useMemo, useState } from 'react';
import { TourDetailsDateFilter } from './TourDetailsDateFilter';
import {
  addDays,
  endOfDay,
  format,
  isEqual,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { ESButton, useESSnackbar } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FleetRoutes } from '@energy-stacks/fleet/shared';
import { useTours } from '../useTours';

type TourDetailsFiltersProps = {
  tourStartDate: string;
  vehicleName: string;
};

const toursDateFilter = {
  dateFrom: startOfDay(subDays(new Date(), 7)),
  dateTo: endOfDay(addDays(new Date(), 30)),
};

export const TourDetailsFilters: React.FC<TourDetailsFiltersProps> = ({
  tourStartDate,
  vehicleName,
}) => {
  const [tShared] = useTranslation('shared');
  const [selectedVehicle, setSelectedVehicle] = useState<string>(vehicleName);

  const initialDate = tourStartDate ? new Date(tourStartDate) : undefined;
  const [date, setDate] = useState<Date | undefined>(initialDate);
  const { data: tours, isFetching } = useTours(toursDateFilter);
  const { showSnackbar } = useESSnackbar();
  const navigate = useNavigate();
  const vehiclesForTheSameDate = useMemo(
    () =>
      (tours ?? [])
        .filter((tour) =>
          isSameDay(new Date(tour.startDate), new Date(tourStartDate))
        )
        .map((tour) => tour.vehicle.name),
    [tourStartDate, tours]
  );

  // All dates (from past seven days onwards) on which the vehicle has tours
  const currentVehiclePlannedDays = useMemo(
    () =>
      (tours ?? [])
        .filter((tour) => tour.vehicle.name === selectedVehicle)
        .map((tour) => tour.startDate),
    [selectedVehicle, tours]
  );

  const handleApplyFilters = useCallback(() => {
    const tour = (tours ?? []).find((tour) => {
      if (tour.startDate && tour.vehicle && selectedVehicle && date) {
        return (
          isSameDay(new Date(tour.startDate), date) &&
          tour.vehicle.name === selectedVehicle
        );
      }

      return undefined;
    });

    if (!tour) {
      // Throw snackbar
      showSnackbar('error', 'noTourForSpecifiedFilters', 'tours');
      return;
    }

    // Navigate to tour details
    navigate(`${FleetRoutes.Tours}/${tour.tourId}`, {
      replace: true,
    });
  }, [date, navigate, selectedVehicle, showSnackbar, tours]);

  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <TourDetailsVehicleFilter
        disabled={isFetching}
        options={vehiclesForTheSameDate}
        vehicleFilter={selectedVehicle}
        onVehicleFilterChange={setSelectedVehicle}
      />
      <TourDetailsDateFilter
        disabled={isFetching}
        title={date ? format(date, 'dd/MM/yyyy') : 'DATE'}
        isActive={!!date}
        date={date}
        onDateChange={setDate}
        currentVehiclePlannedDays={currentVehiclePlannedDays}
      />
      <ESButton
        onClick={handleApplyFilters}
        disabled={
          (date &&
            initialDate &&
            isEqual(date, initialDate) &&
            selectedVehicle === vehicleName) ||
          isFetching
        }
      >
        {tShared('apply')}
      </ESButton>
    </Stack>
  );
};
