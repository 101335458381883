import { TourModel } from '@energy-stacks/fleet/feature-tours-data';
import { VehicleLogo } from '@energy-stacks/fleet/feature-vehicles';
import {
  CancelledJobsWarning,
  TimeWindowWarningPopover,
} from '@energy-stacks/fleet/shared';
import { Stack, Typography, useTheme } from '@mui/material';
import { IconClockExclamation } from '@tabler/icons-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TourContaminationWarning } from './TourContaminationWarning';

type ToursTableVehicleCellProps = {
  brandId: TourModel['vehicle']['name'];
  vehicleName: TourModel['vehicle']['name'];
  isVehicleDeleted: TourModel['vehicle']['isDeleted'];
  isViolatingContaminationRules: TourModel['isViolatingContaminationRules'];
  isViolatingTimeWindow: TourModel['isViolatingTimeWindow'];
  hasCancelledJobs: boolean;
};

export const ToursTableVehicleCell: React.FC<ToursTableVehicleCellProps> = ({
  brandId,
  vehicleName,
  isVehicleDeleted,
  isViolatingContaminationRules,
  isViolatingTimeWindow,
  hasCancelledJobs,
}) => {
  const [t] = useTranslation('tours');
  const { palette } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <VehicleLogo brandId={brandId} />
      <Typography ref={containerRef} variant="inherit" minWidth={0}>
        {vehicleName}
      </Typography>
      {isVehicleDeleted ? (
        <Stack>
          <Typography sx={{ overflow: 'visible' }}>
            {`(${t('vehicleDeleted')})`}
          </Typography>
        </Stack>
      ) : null}
      <Stack direction="row" gap={1.5}>
        {isViolatingTimeWindow ? (
          <TimeWindowWarningPopover message={t('timeWindowWarningMessage')}>
            <IconClockExclamation color={palette.error.dark} />
          </TimeWindowWarningPopover>
        ) : null}
        {isViolatingContaminationRules ? <TourContaminationWarning /> : null}
        {hasCancelledJobs ? <CancelledJobsWarning /> : null}
      </Stack>
    </Stack>
  );
};
