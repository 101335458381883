import { AddVehiclePayload } from '../addVehiclePayload';
import { AddVehicleFormData } from '../addVehicleFormData';
import { workingHoursToPayload } from './workingHoursToPayload';

export const addVehicleDataToPayload = (
  data: AddVehicleFormData,
  coreVehicleId: string
): AddVehiclePayload => {
  return {
    vehicleCoreUuid: coreVehicleId,
    name: data.name,
    depotId: data.plantId.value,
    vehicleIdentificationNumber: data.vehicleId,
    licensePlateNumber: data.licencePlate,
    status: 'AVAILABLE',
    workingHours: {
      ...workingHoursToPayload(data.workingHours),
      // Hardcoded as we don't support it for now, but required on BE
      twentyfourseven: false,
    },
    ownershipType: data.type.value,
    usagePriority: data.group.value,
    skippingPenaltyRules: data.hasUVFilter,
  };
};
