import { JobStatusChip, JobStatusChipProps } from '@energy-stacks/fleet/shared';
import { useDialog } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Dialog, Typography } from '@mui/material';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useChangeJobStatusMutation } from '@energy-stacks/fleet/feature-jobs-data';
import { useCallback } from 'react';
import { EditingTourJob } from './editingTourJobModel';
import { useParams } from 'react-router-dom';
import { jobsApiErrors } from '@energy-stacks/fleet/feature-jobs';

interface TourJobsTableJobStatusChip extends JobStatusChipProps {
  jobId: string;
}

export const TourJobsTableJobStatusChip: React.FC<
  TourJobsTableJobStatusChip
> = ({ status, disabled, jobId }) => {
  const {
    dialogProps: { isOpen, onClose },
    open,
  } = useDialog();

  if (status !== 'ENROUTE') {
    return (
      <JobStatusChip status={status} disabled={disabled} isActionable={false} />
    );
  }

  return (
    <>
      <ButtonBase
        onClick={(e) => {
          e.stopPropagation();
          open();
        }}
      >
        <JobStatusChip status={status} isActionable={true} />
      </ButtonBase>
      <ConfirmStatusChangeDialog
        isOpen={isOpen}
        jobId={jobId}
        onClose={onClose}
      />
    </>
  );
};

type ConfirmStatusChangeDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  jobId: EditingTourJob['jobId'];
};

const ConfirmStatusChangeDialog: React.FC<ConfirmStatusChangeDialogProps> = ({
  isOpen,
  onClose,
  jobId,
}) => {
  const [t] = useTranslation('tours');
  const { tourId } = useParams<{ tourId: string }>();
  const [changeJobStatus, { isLoading }] = useChangeJobStatusMutation();
  const { showSnackbar } = useESSnackbar();

  const handleJobStatusChange = useCallback(() => {
    changeJobStatus({ jobId, status: 'FAILED', tourId: tourId || '' })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        const errorCode = error.data?.errorCode;
        showSnackbar(
          'error',
          errorCode && jobsApiErrors[errorCode]
            ? jobsApiErrors[errorCode]
            : undefined,
          'jobs'
        );
      });
  }, [changeJobStatus, jobId, onClose, showSnackbar, tourId]);

  return (
    <Dialog
      open={isOpen}
      onClick={(e) => e.stopPropagation()}
      fullWidth={true}
      onClose={onClose}
    >
      <ESDialogTitle sx={{ fontSize: 16, paddingBottom: 0 }}>
        {t('changeJobStatusDialog.title')}
      </ESDialogTitle>
      <ESDialogContent>
        <Typography variant="body1">
          {t('changeJobStatusDialog.content')}
        </Typography>
      </ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton
          color="error"
          disabled={isLoading}
          onClick={onClose}
        >
          {t('changeJobStatusDialog.cancel')}
        </ESDialogActionButton>
        <ESDialogActionButton
          variant="contained"
          color="primary"
          onClick={handleJobStatusChange}
          loading={isLoading}
        >
          {t('changeJobStatusDialog.confirm')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  );
};
