import {
  ESWizardContent,
  ESWizardV2,
  Step,
  useESSnackbar,
  useESStepper,
} from '@energy-stacks/core/ui';
import {
  ESFullScreenLoadingIndicator,
  toPayloadDateFullDay,
  ESErrorDialog,
  isErrorWithErrorCode,
  useCloseDialogPrompt,
} from '@energy-stacks/shared';
import { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import {
  OptimizedTours,
  createToursDataToPayload,
  useCreateToursMutation,
  useOptimizeToursMutation,
} from '@energy-stacks/fleet/feature-tours-data';
import { ReviewStep, TourDetails } from './create-tour-steps/ReviewStep';
import { JobsStep } from './create-tour-steps/JobsStep';
import { VehiclesStep } from './create-tour-steps/VehiclesStep';
import { OptimizeStep } from './create-tour-steps/OptimizeStep';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import './shared/tour-wizard.css';
import { useCreateTourState } from './useCreateTourState';
import { toursApiErrors } from './toursApiErrors';
import { Alert, Stack } from '@mui/material';
import {
  FleetRoutes,
  Stepper,
  useNavigateBack,
} from '@energy-stacks/fleet/shared';
import { ContaminationToursConfirmDialog } from './shared/ContaminationToursConfirmDialog';
import { Box } from '@mui/system';
import { SkippedJobsAlert } from './create-tour-steps/SkippedJobsAlert';
import { ContaminationGlobalAlert } from './create-tour-steps/ContaminationGlobalAlert';
import { useConfirmViolatedToursDialog } from './shared/useConfirmViolatedToursDialog';
import { useNavigate } from 'react-router-dom';

type CreateTourStepName = 'jobs' | 'vehicles' | 'optimize' | 'review';

export const CreateTourWizard = () => {
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isEditTourMode, setIsEditTourMode] = useState(false);
  const [isContaminationFilterActive, setIsContaminationFilterActive] =
    useState(false);
  const [tourDetails, setTourDetails] = useState<TourDetails | undefined>();
  const optimizeToursRef = useRef<() => void>(() => {});
  const [
    optimizeTours,
    {
      isLoading: isOptimizingTours,
      isError: errorOptimizingTours,
      error: optimizeTourError,
    },
  ] = useOptimizeToursMutation();
  const [
    createTours,
    {
      isLoading: isCreatingTours,
      isError: errorCreatingTours,
      isSuccess: isCreateToursSuccess,
    },
  ] = useCreateToursMutation();

  const [optimizedToursResponse, setOptimizedToursResponse] =
    useState<OptimizedTours>({
      tours: [],
      skippedJobs: [],
    });

  const {
    state,
    setSelectedJobIds,
    setDateRange,
    setSelectedVehicleIds,
    setOptimization,
    setSelectedTours,
    defaultDateRange,
  } = useCreateTourState();

  const [t] = useTranslation('tours');
  const [tShared] = useTranslation('shared');
  const { showSnackbar } = useESSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setIsWizardOpen(true);
  }, []);

  const handleCloseWizard = useNavigateBack(FleetRoutes.Tours);
  const showCloseConfirmation = useCloseDialogPrompt({
    shouldPrompt: true, // Show prompt whenever the user tries to close the wizard
    promptMessage: t('exitTourWizardConfirmCloseDescription'),
    onClose: () => setIsWizardOpen(false),
  });

  const handleTourOptimization = useCallback(
    (finish: () => void) => {
      if (!state.selectedJobIds || !state.selectedVehicleIds) return;

      optimizeTours({
        startRouteTime: toPayloadDateFullDay(
          state.dateRange.startDate.toISOString()
        ).startOfDay,
        endRouteTime: toPayloadDateFullDay(
          state.dateRange.endDate.toISOString()
        ).endOfDay,
        vehicleIdentificationNumbers: state.selectedVehicleIds,
        jobUids: state.selectedJobIds,
      })
        .unwrap()
        .then((optimizeToursResponse) => {
          if (optimizeToursResponse) {
            setSelectedTours(optimizeToursResponse.tours);
            setOptimizedToursResponse({
              tours: optimizeToursResponse.tours,
              skippedJobs: optimizeToursResponse.skippedJobs,
            });
          }
          finish();
        })
        .catch(() => {
          setShowErrorDialog(true);
        });
    },
    [
      optimizeTours,
      setSelectedTours,
      state.dateRange.endDate,
      state.dateRange.startDate,
      state.selectedJobIds,
      state.selectedVehicleIds,
    ]
  );

  const handleCreateTours = useCallback(() => {
    if (state.selectedTours && state.selectedTours.length > 0) {
      createTours(createToursDataToPayload(state.selectedTours))
        .unwrap()
        .then(() => {
          showSnackbar('success', 'createTourSuccess', 'tours');
          setIsWizardOpen(false);
        })
        .catch(() => {
          setShowErrorDialog(true);
        });
    }
  }, [createTours, showSnackbar, state.selectedTours]);

  const {
    handleTourConfirmation,
    closeConfirmViolatedToursDialog,
    isConfirmViolatedToursDialogOpen,
  } = useConfirmViolatedToursDialog(
    handleCreateTours,
    (state.selectedTours ?? [])
      .map((tour) => {
        return tour.tourJobs.jobs;
      })
      .flat()
  );

  const reviewStepTours = useMemo(() => {
    if (!optimizedToursResponse) {
      return [];
    } else if (!isContaminationFilterActive) {
      return optimizedToursResponse.tours;
    } else {
      return optimizedToursResponse.tours.filter((t) =>
        t.tourJobs.jobs.some((j) => j.contaminationViolation)
      );
    }
  }, [isContaminationFilterActive, optimizedToursResponse]);

  const createTourStepsMap: Record<CreateTourStepName, Step> = useMemo(
    () => ({
      jobs: {
        component: (
          <JobsStep
            selectedJobIds={state.selectedJobIds}
            onJobsSelected={setSelectedJobIds}
            onDateRangeChange={setDateRange}
            defaultDateRange={defaultDateRange}
            dateRange={state.dateRange}
          />
        ),
      },
      vehicles: {
        component: (
          <VehiclesStep
            selectedVehicleIds={state.selectedVehicleIds}
            onVehiclesSelected={setSelectedVehicleIds}
            onDateRangeChange={setDateRange}
            defaultDateRange={defaultDateRange}
            dateRange={state.dateRange}
          />
        ),
      },
      optimize: {
        component: (
          <OptimizeStep
            onOptimizeSelected={setOptimization}
            selectedOption={state.optimize}
          />
        ),
        action: (finish) => {
          const tourOptimizationHandler = () => {
            handleTourOptimization(finish);
          };

          optimizeToursRef.current = tourOptimizationHandler;
          tourOptimizationHandler();
        },
      },
      review: {
        component: (
          <ReviewStep
            optimizedToursResponse={reviewStepTours}
            setOptimizedToursResponse={setOptimizedToursResponse}
            selectedJobsCount={state.selectedJobIds?.length ?? 0}
            onToursSelected={setSelectedTours}
            selectedTours={state.selectedTours}
            isEditTourMode={isEditTourMode}
            setIsEditTourMode={setIsEditTourMode}
            tourDetails={tourDetails}
            setTourDetails={setTourDetails}
          />
        ),
        action: handleTourConfirmation,
      },
    }),
    [
      defaultDateRange,
      handleTourConfirmation,
      handleTourOptimization,
      isEditTourMode,
      reviewStepTours,
      setDateRange,
      setOptimization,
      setSelectedJobIds,
      setSelectedTours,
      setSelectedVehicleIds,
      state.dateRange,
      state.optimize,
      state.selectedJobIds,
      state.selectedTours,
      state.selectedVehicleIds,
      tourDetails,
    ]
  );

  const {
    canGoToNextStep,
    canGoToPrevStep,
    activeStep,
    activeStepIndex,
    activeStepName,
    handleNextStep,
    handlePreviousStep,
    isLastStep,
  } = useESStepper<CreateTourStepName>(createTourStepsMap);

  const goToPreviousStep = useCloseDialogPrompt({
    shouldPrompt: activeStepName === 'review',
    promptMessage: t('goBackConfirmationMessage'),
    onClose: () => {
      handlePreviousStep();
      if (tourDetails) {
        setTourDetails(undefined);
      }
    },
  });

  const contaminatedToursCount = useMemo(
    () =>
      optimizedToursResponse?.tours.filter((tour) =>
        tour.tourJobs.jobs.some((j) => j.contaminationViolation)
      ).length ?? 0,
    [optimizedToursResponse]
  );
  if (!contaminatedToursCount && isContaminationFilterActive) {
    setIsContaminationFilterActive(false);
  }

  return (
    <ESWizardV2
      isOpen={isWizardOpen}
      onClose={showCloseConfirmation}
      onExit={() => {
        if (isCreateToursSuccess) {
          navigate(FleetRoutes.Tours);
          return;
        }
        handleCloseWizard();
      }}
      HeaderComponent={
        <Stepper
          canGoToNextStep={canGoToNextStep}
          canGoToPrevStep={canGoToPrevStep}
          currentStep={activeStepIndex}
          disableNextButton={
            (activeStepName === 'jobs' &&
              (!state.selectedJobIds || state.selectedJobIds?.length === 0)) ||
            (activeStepName === 'vehicles' &&
              (!state.selectedVehicleIds ||
                state.selectedVehicleIds?.length === 0)) ||
            (activeStepName === 'review' &&
              (!state.selectedTours || state.selectedTours.length === 0)) ||
            isEditTourMode ||
            isCreatingTours
          }
          disablePreviousButton={isEditTourMode}
          handleNextStep={handleNextStep}
          handlePreviousStep={goToPreviousStep}
          nextButtonText={t(
            isLastStep ? 'confirmStepButtonLabel' : 'nextStepButtonLabel'
          )}
          steps={Object.keys(createTourStepsMap).map((step) => {
            return {
              stepName: t(`${step}StepLabel`),
            };
          })}
        />
      }
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SwitchTransition>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CSSTransition key={activeStepIndex} classNames="fade" timeout={180}>
          {() => (
            <Stack height="100%" gap={6}>
              {activeStepName === 'review' &&
                !isEditTourMode &&
                optimizedToursResponse &&
                !tourDetails && (
                  <>
                    <Alert variant="filled" severity="info">
                      {t('createTourReviewStepInfoMessage')}
                    </Alert>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 6,
                      }}
                    >
                      <ContaminationGlobalAlert
                        count={contaminatedToursCount}
                        isSwitchOn={isContaminationFilterActive}
                        onSwitchChange={setIsContaminationFilterActive}
                      />
                      <SkippedJobsAlert
                        skippedJobs={optimizedToursResponse?.skippedJobs ?? []}
                        totalJobsCount={state.selectedJobIds?.length ?? 0}
                      />
                    </Box>
                  </>
                )}
              <ESWizardContent>{activeStep}</ESWizardContent>
            </Stack>
          )}
        </CSSTransition>
      </SwitchTransition>
      <ESErrorDialog
        open={showErrorDialog}
        onCancel={() => setShowErrorDialog(false)}
        onTryAgain={() => {
          if (errorOptimizingTours) {
            optimizeToursRef.current?.();
          }

          if (errorCreatingTours) {
            handleCreateTours();
          }
          setShowErrorDialog(false);
        }}
        errorMessage={
          isErrorWithErrorCode(optimizeTourError) &&
          toursApiErrors[optimizeTourError?.data?.errorCode]
            ? t(
                `toursApiErrors.${
                  toursApiErrors[optimizeTourError?.data?.errorCode]
                }`
              )
            : undefined
        }
      />
      {isOptimizingTours ? (
        <ESFullScreenLoadingIndicator text={t('createTourPlanLoadingText')} />
      ) : null}
      {isCreatingTours ? (
        <ESFullScreenLoadingIndicator text={tShared('loading')} />
      ) : null}
      <ContaminationToursConfirmDialog
        isOpen={isConfirmViolatedToursDialogOpen}
        onCancel={closeConfirmViolatedToursDialog}
        onConfirm={() => {
          closeConfirmViolatedToursDialog();
          handleCreateTours();
        }}
        isLoading={isCreatingTours}
      />
    </ESWizardV2>
  );
};
