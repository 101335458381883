import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearch = (shouldPersist = false) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') ?? '');

  const handleSearch = useCallback(
    (value: string) => {
      const trimmedNewValue = value.trim();
      setSearch(trimmedNewValue);
      if (shouldPersist) {
        setSearchParams((prev) => {
          prev.set('search', trimmedNewValue);
          return prev;
        });
      }
    },
    [setSearchParams, shouldPersist]
  );

  return { search, setSearch: handleSearch };
};
