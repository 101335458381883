import { productNameMap } from '@energy-stacks/fleet/shared';
import { BusinessAccountDetailsDto } from '../businessAccountDetailsDto';
import {
  BusinessAccountDetailsModel,
  BusinessAccountJobLocation,
} from '../businessAccountDetailsModel';
import { businessAccountLocationsNormalizer } from './businessAccountLocationsNormalizer';

export const businessAccountDetailsNormalizer = (
  businessAccount: BusinessAccountDetailsDto
): BusinessAccountDetailsModel => {
  return {
    id: businessAccount.uid,
    companyName: businessAccount.companyName,
    contact: businessAccount.contact
      ? {
          firstName: businessAccount.contact.firstName,
          lastName: businessAccount.contact.lastName,
          phoneNumber: businessAccount.contact.phoneNumber,
          email: businessAccount.contact.email,
        }
      : {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
        },
    geoLocation: businessAccount.geoLocation
      ? {
          latitude: businessAccount.geoLocation.latitude,
          longitude: businessAccount.geoLocation.longitude,
        }
      : {
          latitude: '',
          longitude: '',
        },
    address: businessAccount.address
      ? {
          street: businessAccount.address.street,
          zipCode: businessAccount.address.zipCode,
          city: businessAccount.address.city,
        }
      : {
          street: '',
          zipCode: '',
          city: '',
        },
    recentJobs: businessAccountRecentJobsNormalizer(
      businessAccount.recentJobs,
      businessAccount.locationAddressTypeGroups
    ),
    locations: businessAccountLocationsNormalizer(
      businessAccount.locationAddressTypeGroups
    ),
  };
};

export const businessAccountRecentJobsNormalizer = (
  recentJobsDto: BusinessAccountDetailsDto['recentJobs'],
  locationGroupsDto?: BusinessAccountDetailsDto['locationAddressTypeGroups']
): BusinessAccountDetailsModel['recentJobs'] => {
  return (recentJobsDto || []).map((recentJob) => {
    const rawLocations = locationGroupsDto?.['Raw Tank'] || [];
    const degassedLocations = locationGroupsDto?.['Degassed Tank'] || [];

    let jobLocation: BusinessAccountJobLocation | undefined;

    rawLocations.forEach((location) => {
      if (location.uid === recentJob.locationUid) {
        jobLocation = {
          id: location.uid,
          risk: location.risk,
          type: location.type,
          productType: 'raw',
        };
      }
    });

    if (!jobLocation) {
      degassedLocations.forEach((location) => {
        if (location.uid === recentJob.locationUid) {
          jobLocation = {
            id: location.uid,
            risk: location.risk,
            type: location.type,
            productType: 'degassed',
          };
        }
      });
    }

    return {
      id: recentJob.jobUid,
      location: jobLocation
        ? {
            id: jobLocation.id,
            risk: jobLocation.risk,
            type: jobLocation.type,
            productType: jobLocation.productType,
          }
        : {
            id: '',
            risk: '',
            type: '',
            productType: '',
          },
      productName:
        productNameMap[recentJob.productName] ?? recentJob.productName,
      date: recentJob.dateDropOffTill,
    };
  });
};
