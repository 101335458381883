import {
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { BusinessAccountsTable } from './BusinessAccountsTable';
import { FleetPage } from '@energy-stacks/fleet/shared';
import {
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  useESTableServerControls,
} from '@energy-stacks/core/ui';
import {
  BusinessAccountsTableSortOrderModel,
  useGetPaginatedBusinessAccountsQuery,
} from '@energy-stacks/fleet/feature-business-accounts-data';

export const BusinessAccountsPage = () => {
  const [t] = useTranslation('businessAccounts');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);

  const { pagination, setPagination, search, setSearch, sorting, setSorting } =
    useESTableServerControls({
      shouldPersistState: true,
      defaultRowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
      initialSorting: [{ id: 'id', desc: true }],
    });

  const { data, isError, isFetching, isLoading, refetch } =
    useGetPaginatedBusinessAccountsQuery({
      searchValue: search || undefined,
      page: pagination.pageIndex,
      size: pagination.pageSize,
      sort:
        sorting.order && sorting.column
          ? ({
              id: sorting.column,
              order: sorting.order,
            } as BusinessAccountsTableSortOrderModel)
          : undefined,
    });

  return (
    <FleetPage title={pageTitle}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data && !isError ? (
        <BusinessAccountsTable
          businessAccounts={data}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting.state}
          onSortingChange={setSorting}
          search={search}
          setSearch={setSearch}
          isFetching={isFetching}
        />
      ) : null}
    </FleetPage>
  );
};
