import {
  ESTable,
  ESTableBasicConfig,
  ESTableBodyCell,
  ESTableBodyCompose,
  ESTableBodyRow,
  ESTableHead,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { OptimizedTourModel } from '@energy-stacks/fleet/feature-tours-data';
import { NoTableData, TableRowsCount, formatDate } from '@energy-stacks/shared';
import { createColumnHelper, flexRender, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { isWithinInterval, parseISO } from 'date-fns';
import { TourDetailsVehicleChip } from './tour-details/TourDetailsVehicleChip';
import { ToursDurationCell } from './shared/ToursDurationCell';
import { ToursQuantityCell } from './shared/ToursQuantityCell';
import { sortByQuantity } from './shared/sortByQuantity';
import { Stack, useTheme } from '@mui/material';
import { TourContaminationWarning } from './shared/TourContaminationWarning';
import { TourDurationCellEmptyRuns } from './shared/TourDurationCellEmptyRuns';
import { TimeWindowWarningPopover } from '@energy-stacks/fleet/shared';
import { IconClockExclamation } from '@tabler/icons-react';

interface OptimizedToursTableProps
  extends ESTableBasicConfig<OptimizedTourModel> {
  tours: OptimizedTourModel[];
  onRowClick?: (row: Row<OptimizedTourModel>) => void;
}

const TABLE_CELL_PADDING = 16;
const CHECKBOX_WIDTH = 24;

export const OptimizedToursTable: React.FC<OptimizedToursTableProps> = ({
  tours,
  onRowClick,
  enableRowSelection,
  defaultRowSelection,
  onSelectionChange,
}) => {
  const [t] = useTranslation('tours');
  const columnHelper = createColumnHelper<OptimizedTourModel>();
  const { palette } = useTheme();

  const columns = [
    columnHelper.accessor('vehicleName', {
      header: () => t('vehicleColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => (
        <Stack direction="row" gap={2} alignItems="center">
          <TourDetailsVehicleChip
            title={info.getValue()}
            index={info.row.index}
          />
          {info.row.original.isViolatingTimeWindow ? (
            <TimeWindowWarningPopover message={t('timeWindowWarningMessage')}>
              <IconClockExclamation color={palette.error.dark} />
            </TimeWindowWarningPopover>
          ) : null}
        </Stack>
      ),
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('date', {
      header: () => t('dateColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => formatDate(info.getValue()),
      enableColumnFilter: true,
      filterFn: (row, _, filterValue: DateRange | undefined) => {
        if (!filterValue) {
          return true;
        }

        return isWithinInterval(parseISO(row.original.date), {
          start: filterValue.startDate,
          end: filterValue.endDate,
        });
      },
    }),
    columnHelper.accessor('startDate', {
      header: () => t('startTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => formatDate(info.getValue(), 'HH:mm').toLowerCase(),
    }),
    columnHelper.accessor('endDate', {
      header: () => t('endTimeColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => formatDate(info.getValue(), 'HH:mm').toLowerCase(),
    }),
    columnHelper.accessor('duration', {
      header: () => t('durationColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        const { startDate, endDate, distance, emptyRunsMetrics } = row.original;
        return (
          <Stack>
            <ToursDurationCell
              startTime={startDate}
              endTime={endDate}
              distance={distance}
            />
            {emptyRunsMetrics.emptyRunsTotalNumber ? (
              <TourDurationCellEmptyRuns
                emptyRunsCount={emptyRunsMetrics.emptyRunsTotalNumber}
                totalEmptyRunsDurationInSeconds={
                  emptyRunsMetrics.emptyRunsTotalDurationSeconds
                }
              />
            ) : null}
          </Stack>
        );
      },
    }),
    columnHelper.accessor('totalJobs', {
      id: 'jobs',
      header: () => t('jobsColumnHeader'),
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('quantity', {
      header: () => t('quantityColumnHeader'),
      footer: (props) => props.column.id,
      cell: ({ row }) =>
        row.original.quantity ? (
          <ToursQuantityCell quantity={row.original.quantity} />
        ) : (
          '---'
        ),
      sortingFn: sortByQuantity<OptimizedTourModel>,
    }),
  ];

  const { instance, rows } = useESTableBasic(tours, columns, {
    fitRowHeight: 74,
    onSelectionChange,
    enableRowSelection,
    defaultRowSelection,
    getRowId: (row) => JSON.stringify(row),
    manualPagination: true,
  });

  return (
    <ESTableWrapper>
      <ESTable>
        <ESTableHead instance={instance} />
        <ESTableBodyCompose>
          {rows.map((row) => {
            const isViolating = row.original.tourJobs.jobs.some(
              (j) => j.contaminationViolation
            );
            return (
              <ESTableBodyRow key={row.id} onRowClick={() => onRowClick?.(row)}>
                {row.getVisibleCells().map((cell, i) => {
                  return (
                    <ESTableBodyCell
                      key={cell.id}
                      sx={{
                        '&': {
                          backgroundColor: cell.row.getIsSelected()
                            ? 'primary.light'
                            : cell.column.columnDef.meta?.backgroundColor,
                          padding: cell.column.columnDef.meta?.cellPadding,
                          position: 'relative',
                          whiteSpace: 'nowrap',
                          width: cell.column.getSize(),
                        },
                      }}
                    >
                      <Stack alignItems="center" flexDirection="row">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                        {i === 0 && isViolating && (
                          <TourContaminationWarning
                            sx={{
                              position: 'relative',
                              left: `calc((100% - ${CHECKBOX_WIDTH}px + (2 * ${TABLE_CELL_PADDING}px)) / 2)`,
                              transform: 'translate(-50%)',
                            }}
                          />
                        )}
                      </Stack>
                    </ESTableBodyCell>
                  );
                })}
              </ESTableBodyRow>
            );
          })}
        </ESTableBodyCompose>
      </ESTable>

      {rows.length === 0 ? (
        <NoTableData message={t('noToursTableMessage')} />
      ) : null}
      <TableRowsCount
        instance={instance}
        totalCountLabel={t('totalRowsCount')}
      />
    </ESTableWrapper>
  );
};
