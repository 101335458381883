import { BackendSortingOrderModel } from '@energy-stacks/core/ui';
import { BusinessAccountDto } from './businessAccountDto';
import { BusinessAccountModel } from './businessAccountModel';

type BusinessAccountsTableSortColumnsDto =
  | keyof Pick<BusinessAccountDto, 'companyName' | 'uid'>;

type BusinessAccountsTableSortColumns =
  | keyof Pick<BusinessAccountModel, 'companyName' | 'id'>;

const sortColumnsMap: Record<
  BusinessAccountsTableSortColumns,
  BusinessAccountsTableSortColumnsDto
> = {
  id: 'uid',
  companyName: 'companyName',
};

export type BusinessAccountsTableBackendSortOrderModel =
  BackendSortingOrderModel & {
    id: BusinessAccountsTableSortColumns;
  };

export type BusinessAccountsTableSortOrderModel = BackendSortingOrderModel & {
  id: BusinessAccountsTableSortColumns;
};

export const mapBusinessAccountsTableSortIdToPayload = (
  sort: BusinessAccountsTableBackendSortOrderModel
): [string] => [`${sortColumnsMap[sort.id]},${sort.order}`];
