import { Stack, CircularProgress, Typography } from '@mui/material';
import React from 'react';

type ToursTableProgressCellProps = {
  doneJobsCount: number;
  totalJobsCount: number;
};

export const ToursTableProgressCell: React.FC<ToursTableProgressCellProps> = ({
  doneJobsCount,
  totalJobsCount,
}) => {
  return (
    <Stack direction="row" rowGap={2} alignItems="center">
      <CircularProgress
        value={100}
        variant="determinate"
        size={20}
        thickness={5.5}
        sx={{
          position: 'absolute',
          color: doneJobsCount ? 'success.light' : 'grey.300',
        }}
      />
      <CircularProgress
        value={(doneJobsCount * 100) / totalJobsCount}
        variant="determinate"
        size={20}
        thickness={5.5}
        sx={{
          color: 'success.dark',
          mr: 3,
        }}
      />
      <Typography variant="inherit" fontWeight={400}>
        {`${doneJobsCount}/${totalJobsCount}`}
      </Typography>
    </Stack>
  );
};
