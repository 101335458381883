import { BackendSortingOrderModel } from '@energy-stacks/core/ui';
import { JobDto } from './jobDto';
import { JobModel } from './jobModel';

type JobsTableSortColumnsDto =
  | keyof Pick<JobDto, 'jobUid' | 'businessAccountUid'>
  | 'originLocationUid'
  | 'destinationLocationUid';

type JobsTableSortColumns =
  | keyof Pick<JobModel, 'jobId' | 'supplier' | 'origin' | 'destination'>;

const sortColumnsMap: Record<JobsTableSortColumns, JobsTableSortColumnsDto> = {
  jobId: 'jobUid',
  supplier: 'businessAccountUid',
  origin: 'originLocationUid',
  destination: 'destinationLocationUid',
};

export type JobsTableBackendSortOrderModel = BackendSortingOrderModel & {
  id: JobsTableSortColumns;
};

export type JobsTableSortOrderModel = BackendSortingOrderModel & {
  id: JobsTableSortColumns;
};

export const mapJobsTableSortIdToPayload = (
  sort: JobsTableBackendSortOrderModel
): [string] => [`${sortColumnsMap[sort.id]},${sort.order}`];
