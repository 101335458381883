import {
  ESTable,
  ESTableBody,
  ESTableHead,
  ESTableWrapper,
  useESTableBasic,
} from '@energy-stacks/core/ui';
import { useRemovePlantIdPrefix } from '@energy-stacks/fleet/feature-business-accounts';
import { JobDueDateCell, useJobs } from '@energy-stacks/fleet/feature-jobs';
import { JobModel } from '@energy-stacks/fleet/feature-jobs-data';
import { SkippedJob } from '@energy-stacks/fleet/feature-tours-data';
import { ProductTypeChip } from '@energy-stacks/fleet/shared';
import { NoTableData } from '@energy-stacks/shared';
import { Stack, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type SkippedJobsTableProps = {
  skippedJobs: SkippedJob[];
};
export const SkippedJobsTable: FC<SkippedJobsTableProps> = ({
  skippedJobs,
}) => {
  const [t] = useTranslation('jobs');

  const { data: jobs } = useJobs();
  const skippedJobsDetails = useMemo(() => {
    const skippedJobsIds = skippedJobs.map((sj) => sj.jobId);
    return jobs?.filter((j) => skippedJobsIds.includes(j.jobId)) ?? [];
  }, [skippedJobs, jobs]);
  const removePlantIdPrefix = useRemovePlantIdPrefix();

  const columnHelper = createColumnHelper<JobModel>();
  const columns = [
    columnHelper.accessor((row) => removePlantIdPrefix(row.jobId), {
      id: 'jobId',
      sortingFn: 'alphanumeric',
      header: () => t('jobId'),
      footer: (props) => props.column.id,
      cell: (info) => removePlantIdPrefix(info.getValue()),
      size: 140,
    }),
    columnHelper.accessor(
      (row) =>
        `${removePlantIdPrefix(row.supplier.supplierId)} ${
          row.supplier.supplierName
        }`,
      {
        id: 'supplier',
        header: () => t('supplier'),
        footer: (props) => props.column.id,
        size: 200,
        cell: ({ row }) => {
          return (
            <Stack direction="column">
              <Typography variant="inherit">
                {removePlantIdPrefix(row.original.supplier.supplierId)}
              </Typography>
              <Typography variant="inherit">
                {row.original.supplier.supplierName}
              </Typography>
            </Stack>
          );
        },
      }
    ),
    columnHelper.accessor((row) => row.product?.name, {
      id: 'product',
      header: () => t('productType'),
      footer: (props) => props.column.id,
      size: 125,
      cell: ({ row }) => (
        <ProductTypeChip productName={row.original.product.name} />
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('dueDate', {
      header: () => t('dueDate'),
      footer: (props) => props.column.id,
      cell: (info) => <JobDueDateCell dueDate={info.getValue()} />,
      size: 130,
    }),
  ];

  const { instance, rows } = useESTableBasic(skippedJobsDetails, columns, {
    getRowId: (row) => row.jobId,
    manualPagination: true,
  });

  return (
    <ESTableWrapper>
      <ESTable maxHeight="42vh" minWidth={0}>
        <ESTableHead instance={instance} />
        <ESTableBody rows={rows} />
      </ESTable>
      {rows.length === 0 ? <NoTableData message={t('thereAreNoJobs')} /> : null}
    </ESTableWrapper>
  );
};
