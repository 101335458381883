import { Dialog, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  editTourNoteDataToPayload,
  TourModel,
  useEditTourNoteMutation,
} from '@energy-stacks/fleet/feature-tours-data';
import { useCallback, useMemo } from 'react';
import { toursApiErrors } from './toursApiErrors';

type EditTourNoteDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  initialNote: TourModel['note'];
  tourId: TourModel['tourId'];
};

type EditTourNoteFormData = {
  note: string;
};

const editTourNoteValidationSchema = yup.object().shape({
  note: yup.string().max(100, 'tourNoteTooBig'),
});

export const EditTourNoteDialog: React.FC<EditTourNoteDialogProps> = ({
  onClose,
  isOpen,
  initialNote,
  tourId,
}) => {
  const [t] = useTranslation('tours');
  const defaultValues: EditTourNoteFormData = useMemo(
    () => ({
      note: initialNote,
    }),
    [initialNote]
  );

  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState: { isValid, errors, isDirty },
  } = useForm<EditTourNoteFormData>({
    resolver: yupResolver(editTourNoteValidationSchema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { showSnackbar } = useESSnackbar();

  const [editTourNote, { isLoading }] = useEditTourNoteMutation();

  const onSubmit: SubmitHandler<EditTourNoteFormData> = ({ note }) => {
    editTourNote({
      notes: editTourNoteDataToPayload(note),
      tourId,
    })
      .unwrap()
      .then(() => {
        showSnackbar('success', 'editTourNoteSuccess', 'tours');
        onClose();
      })
      .catch((error) => {
        const errorCode = toursApiErrors[error.data?.errorCode];
        showSnackbar(
          'error',
          errorCode ? `toursApiErrors.${errorCode}` : undefined,
          'tours'
        );
      });
  };

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={handleClose}
      sx={{ width: 650, margin: '0 auto' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ESDialogTitle sx={{ paddingBottom: 0 }}>
          {t('editTourNoteDialogTitle')}
        </ESDialogTitle>
        <ESDialogContent>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextField
                  data-testid="editTourNoteField"
                  value={value}
                  placeholder={t('editTourNoteFiledPlaceholder')}
                  onChange={onChange}
                  onBlur={onBlur}
                  multiline
                  minRows={4}
                  fullWidth
                  error={Boolean(errors.note)}
                  helperText={
                    errors.note && errors.note.message
                      ? t(`${errors.note.message}`)
                      : undefined
                  }
                />
              );
            }}
            name={'note'}
          />
        </ESDialogContent>
        <ESDialogActions>
          <ESDialogActionButton
            variant="text"
            color="error"
            onClick={handleClose}
          >
            {t('editTourNoteCancelButtonLabel')}
          </ESDialogActionButton>
          <ESDialogActionButton
            disabled={!isValid || !isDirty}
            loading={isLoading}
            variant="contained"
            onClick={() => {}}
            type="submit"
          >
            {t('editTourNoteSaveButtonLabel')}
          </ESDialogActionButton>
        </ESDialogActions>
      </form>
    </Dialog>
  );
};
