import { useDocumentTitle } from '@energy-stacks/shared';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';
import { JobsTable } from './JobsTable';
import { FleetPage } from '@energy-stacks/fleet/shared';
import { usePaginatedJobs } from './usePaginatedJobs';
import {
  useESTableServerFilterControls,
  useESTableServerControls,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@energy-stacks/core/ui';
import {
  JobModel,
  JobsTableSortOrderModel,
} from '@energy-stacks/fleet/feature-jobs-data';

const SCROLL_RESTORATION_ID = 'jobsTable';

export type JobFilterModel = Pick<JobModel, 'jobType' | 'status' | 'startDate'>;

export const JobsPage = () => {
  const [t] = useTranslation('jobs');
  const pageTitle = t('pageTitle');
  useDocumentTitle(pageTitle);

  const { filtersState, setFilters, tableColumnFilters } =
    useESTableServerFilterControls<JobFilterModel>();

  const { pagination, setPagination, search, setSearch, sorting, setSorting } =
    useESTableServerControls({
      defaultRowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    });

  const { data, isError, isFetching, isLoading, refetch } = usePaginatedJobs({
    search: search || undefined,
    pagination: {
      page: pagination.pageIndex,
      size: pagination.pageSize,
    },
    sort:
      sorting.order && sorting.column
        ? ({
            id: sorting.column,
            order: sorting.order,
          } as JobsTableSortOrderModel)
        : undefined,
    startDate: filtersState.startDate?.startDate,
    endDate: filtersState.startDate?.endDate,
    statuses: filtersState.status,
    jobTypes: filtersState.jobType,
  });

  return (
    <FleetPage title={pageTitle}>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data && !isError ? (
        <JobsTable
          jobs={data}
          enableColumnSelection
          scrollRestorationId={SCROLL_RESTORATION_ID}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting.state}
          onSortingChange={setSorting}
          search={search}
          setSearch={setSearch}
          isFetching={isFetching}
          setFilters={setFilters}
          tableColumnFilters={tableColumnFilters}
        />
      ) : null}
    </FleetPage>
  );
};
