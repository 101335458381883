import { BackendSortingOrderModel } from '@energy-stacks/core/ui';
import { TourDto } from './tourDto';
import { TourModel } from './tourModel';

type ToursTableSortColumnsDto = keyof Pick<
  TourDto,
  'tourUid' | 'status' | 'startDate' | 'endDate' | 'totalTime' | 'notes'
>;

type ToursTableSortColumns =
  | keyof Pick<
      TourModel,
      'tourId' | 'status' | 'startDate' | 'endDate' | 'note' | 'date'
    >
  | 'duration_time';

const sortColumnsMap: Record<ToursTableSortColumns, ToursTableSortColumnsDto> =
  {
    duration_time: 'totalTime',
    startDate: 'startDate',
    endDate: 'endDate',
    note: 'notes',
    status: 'status',
    tourId: 'tourUid',
    date: 'startDate',
  };

export type ToursTableBackendSortOrderModel = BackendSortingOrderModel & {
  id: ToursTableSortColumns;
};

export type ToursTableSortOrderModel = BackendSortingOrderModel & {
  id: ToursTableSortColumns;
};

export const mapSortIdToPayload = (
  sort: ToursTableBackendSortOrderModel
): [string] => {
  return [`${sortColumnsMap[sort.id]},${sort.order}`];
};
