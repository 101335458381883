import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ColumnFiltersState,
  FilterFn,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  RowSelectionState,
  ColumnDef,
  Row,
  ExpandedState,
  SortingState,
  Updater,
  TableState,
  PaginationState,
  OnChangeFn,
} from '@tanstack/react-table';
import { rankItem, Ranking } from '@tanstack/match-sorter-utils';
import { Checkbox } from '@mui/material';
import { useIsFirstRender, useLocalStorage } from 'usehooks-ts';
import { usePersistTableColumnWidths } from './usePersistTableColumnWidths';
import { useTableColumnResizeObserver } from './useTableColumnResizeObserver';

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: 1.5 as Ranking,
  });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export type ESTableServerConfig<TData> = {
  tableId?: string;
  hiddenColumnsIds?: (
    | keyof TData
    | 'actions'
    | 'expand'
    | 'select'
    | 'edit'
    | 'delete'
  )[];
  enableRowSelection?: boolean | ((row: Row<TData>) => boolean);
  onSelectionChange?: (rowSelection: RowSelectionState) => void;
  defaultRowSelection?: (row: TData) => boolean;
  getRowId?: (originalRow: TData, index: number, parent?: Row<TData>) => string;
  onPaginationChange?: (pagination: Updater<PaginationState>) => void;
  enableColumnResizing?: boolean;
  initialColumnVisibility?: VisibilityState;
  onSortingChange?: (sorting: Updater<SortingState>) => void;
  state?: Partial<TableState>;
  rowCount?: number;
  onGlobalFilterChange?: (value: string) => void;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState> | undefined;
};

// Covers use case of simple table with FE filtering including global search, column filters, sorting, and pagination
export const useESTableServer = <T,>(
  data: T[] | undefined,
  columns: ColumnDef<T, any>[],
  config?: ESTableServerConfig<T>
) => {
  const {
    tableId,
    hiddenColumnsIds = [],
    defaultRowSelection = () => false,
    enableRowSelection = false,
    onSelectionChange,
    getRowId,
    onPaginationChange,
    enableColumnResizing = false,
    initialColumnVisibility = {},
    onSortingChange,
    state = {},
    rowCount,
    onGlobalFilterChange,
    onColumnFiltersChange,
  } = config ?? {};
  const rowSelectionState = useMemo(() => {
    if (!getRowId || !data) {
      return {};
    }

    return data
      .filter((row) => defaultRowSelection(row))
      .reduce((acc, item, index) => {
        const id = getRowId(item, index);
        if (id) {
          acc[id] = true;
        }
        return acc;
      }, {} as Record<string, boolean>);
  }, [data, defaultRowSelection, getRowId]);

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const onRowSelectionChangeRef = useRef(onSelectionChange);

  if (enableRowSelection) {
    columns.unshift({
      enableHiding: false,
      id: 'select',
      meta: { isActionable: true },
      header: ({ table }) => (
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          sx={{ padding: 0 }}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => {
        return (
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            sx={{ padding: 0 }}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        );
      },
      size: 50,
    });
  }

  const [persistedColumnVisibility, setPersistedColumnVisibility] =
    useLocalStorage(`table-column-select-${tableId}`, initialColumnVisibility);

  const hiddenColumnsVisibility = useMemo(
    () =>
      hiddenColumnsIds.reduce((acc, id) => {
        acc[String(id)] = false;
        return acc;
      }, {} as VisibilityState),
    [hiddenColumnsIds]
  );

  const { persistedColumnWidths, handlePersistTableColumnWidth } =
    usePersistTableColumnWidths(tableId);

  const isFirstRender = useIsFirstRender();

  const instance = useReactTable({
    data: data ?? [],
    columns,
    initialState: {
      columnFilters: state.columnFilters,
      columnSizing: persistedColumnWidths,
    },
    state: {
      rowSelection: rowSelectionState,
      columnVisibility: {
        ...persistedColumnVisibility,
        ...hiddenColumnsVisibility,
      },
      expanded,
      ...state,
    },
    defaultColumn: {
      minSize: 100, // Can be overwritten through column definition from Table components
    },
    rowCount,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: onPaginationChange,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: (value: string) => {
      const trimmedNewValue = value.trim();
      instance.resetPageIndex();
      onGlobalFilterChange?.(trimmedNewValue);
    },
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: (sorting) => {
      instance.resetPageIndex();
      onSortingChange?.(sorting);
    },
    onRowSelectionChange: (selection) => {
      const newRowSelection =
        typeof selection === 'function'
          ? selection(rowSelectionState)
          : selection;
      onRowSelectionChangeRef.current?.(newRowSelection);
    },
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: (updater) => {
      if (!isFirstRender) {
        instance.resetPageIndex();
      }
      onColumnFiltersChange?.(updater);
    },
    onColumnVisibilityChange: setPersistedColumnVisibility,
    enableRowSelection,
    getRowId,
    onExpandedChange: setExpanded,
    enableColumnResizing,
    columnResizeMode: 'onChange',
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
  });

  useTableColumnResizeObserver(instance, handlePersistTableColumnWidth);

  const canPreviousPage = instance.getCanPreviousPage();

  const handleSelectRow = useCallback(
    (row: Row<T>) => {
      if (enableRowSelection) {
        const toggleRowSelected = row.getToggleSelectedHandler();
        toggleRowSelected(row);
      }
    },
    [enableRowSelection]
  );

  const rows = instance.getRowModel().rows;

  useEffect(() => {
    if (rows.length === 0 && canPreviousPage) {
      instance.previousPage();
    }
  }, [canPreviousPage, rows.length, instance]);

  return useMemo(
    () => ({
      rows,
      instance,
      enableRowSelection,
      handleSelectRow,
    }),
    [enableRowSelection, handleSelectRow, instance, rows]
  );
};
