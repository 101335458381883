import { Box, Grid, Stack, Typography } from '@mui/material';
import { TourDetailsVehicleChip } from './TourDetailsVehicleChip';
import { IconClockHour5 } from '@tabler/icons-react';
import { IconChecklist } from '@tabler/icons-react';
import { formatDate } from '@energy-stacks/shared';
import { formatDuration } from '@energy-stacks/core/ui';
import { TourStatusChip } from '../TourStatusChip';
import { TourInfo } from './TourDetails';
import { useTranslation } from 'react-i18next';
import { useDifferentLocationsCount } from '../shared/useDifferentLocationsCount';
import { TourInfoHeaderItemLayout } from '../shared/TourInfoHeaderItemLayout';
import { EmptyRunsTourInfoItem } from './EmptyRunsTourInfoItem';
import { TourInfoStartDateButton } from './TourInfoStartDateButton';

type TourInfoBannerProps = {
  tourInfo: TourInfo;
  Actions?: React.FC;
};

export const TourInfoBanner: React.FC<TourInfoBannerProps> = ({
  tourInfo,
  Actions,
}) => {
  const { t } = useTranslation('tours');
  const differentLocationsCount = useDifferentLocationsCount(
    tourInfo.tourJobs.jobs
  );

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: 3,
      }}
      padding={4}
      gap={4}
    >
      <TourDetailsVehicleChip
        title={tourInfo.vehicleName}
        index={tourInfo.tourIndex}
        isDeleted={tourInfo.isVehicleDeleted}
      />
      {tourInfo.tourStatus ? (
        <TourStatusChip status={tourInfo.tourStatus} />
      ) : null}
      <TourInfoStartDateButton startDate={tourInfo.startDate} />
      <TourInfoHeaderItemLayout Icon={IconClockHour5}>
        <Typography variant="body1" color="grey.500">
          <Stack component="span" direction="row" gap={1}>
            {formatDate(tourInfo.startDate, 'h:mm a').toLowerCase()}
            <Box component="span">-</Box>
            {formatDate(tourInfo.endDate, 'h:mm a').toLowerCase()}
            <Box component="span" color="grey.900">
              •
            </Box>
            {formatDuration(
              new Date(tourInfo.startDate),
              new Date(tourInfo.endDate),
              'hh:mm'
            )}
            <Box component="span" color="grey.900">
              •
            </Box>
            {tourInfo.distance.toFixed(1)} km
          </Stack>
        </Typography>
      </TourInfoHeaderItemLayout>
      {tourInfo.emptyRunsMetrics?.emptyRunsTotalNumber ? (
        <EmptyRunsTourInfoItem emptyRunsMetrics={tourInfo.emptyRunsMetrics} />
      ) : null}
      <TourInfoHeaderItemLayout Icon={IconChecklist}>
        <Typography variant="body1" color="grey.500">
          <Stack component="span" direction="row" gap={1}>
            {t('jobsCount', {
              count: tourInfo.totalJobs,
            })}
            <Box component="span" color="grey.900">
              •
            </Box>
            {t('differentLocationsCount', {
              count: differentLocationsCount,
            })}
          </Stack>
        </Typography>
      </TourInfoHeaderItemLayout>
      <Stack marginLeft="auto" direction="row" gap={3}>
        {Actions ? <Actions /> : null}
      </Stack>
    </Grid>
  );
};
