import {
  JobModel,
  JobsTableSortOrderModel,
} from '@energy-stacks/fleet/feature-jobs-data';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { JobsTable } from './JobsTable';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import {
  useESTableServerFilterControls,
  useESTableServerControls,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@energy-stacks/core/ui';
import { usePaginatedJobs } from './usePaginatedJobs';
import { JobFilterModel } from './JobsPage';

interface JobsProps {
  onJobsSelected: (jobs: JobModel['jobId'][]) => void;
  selectedJobIds: JobModel['jobId'][] | undefined;
  defaultDateRange: DateRange;
  dateRange?: DateRange | undefined;
  preselectedJobStatusFilters?: JobModel['status'][];
  onDateRangeChange?: (dateRange: DateRange | undefined) => void;
}

export const Jobs: React.FC<JobsProps> = ({
  onJobsSelected,
  preselectedJobStatusFilters,
  defaultDateRange,
  onDateRangeChange,
  dateRange,
  selectedJobIds,
}) => {
  const { filtersState, setFilters, tableColumnFilters } =
    useESTableServerFilterControls<JobFilterModel>({
      defaultFilters: [
        {
          id: 'startDate',
          value: dateRange || defaultDateRange,
        },
        { id: 'status', value: preselectedJobStatusFilters },
      ],
    });

  const { pagination, setPagination, search, setSearch, sorting, setSorting } =
    useESTableServerControls({
      defaultRowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    });

  const { data, isError, isFetching, isLoading, refetch } = usePaginatedJobs({
    search: search || undefined,
    pagination: {
      page: pagination.pageIndex,
      size: pagination.pageSize,
    },
    sort:
      sorting.order && sorting.column
        ? ({
            id: sorting.column,
            order: sorting.order,
          } as JobsTableSortOrderModel)
        : undefined,
    startDate: filtersState.startDate?.startDate,
    endDate: filtersState.startDate?.endDate,
    statuses: filtersState.status,
    jobTypes: filtersState.jobType,
  });

  return (
    <>
      {isLoading ? <ESPageLoadingIndicator /> : null}
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {data && !isError ? (
        <JobsTable
          tableId="create-tour-wizard-jobs-table"
          excludeStatusFilter
          jobs={data}
          enableColumnSelection
          enableRowSelection
          hiddenColumnsIds={['startDate', 'endDate']}
          defaultRowSelection={(item) => !!selectedJobIds?.includes(item.jobId)}
          onSelectionChange={(jobsRowSelection) => {
            onJobsSelected(Object.keys(jobsRowSelection));
          }}
          defaultDateRange={defaultDateRange}
          onDateRangeChange={onDateRangeChange}
          setFilters={setFilters}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting.state}
          onSortingChange={setSorting}
          search={search}
          setSearch={setSearch}
          tableColumnFilters={tableColumnFilters}
          isFetching={isFetching}
        />
      ) : null}
    </>
  );
};
