import { useMemo } from 'react';
import { useSyncTableFiltersWithSearchParams } from './useSyncTableFiltersWithSearchParams';
import { ColumnFiltersState } from '@tanstack/react-table';

type Config = {
  shouldPersist?: boolean;
  defaultFilters?: ColumnFiltersState;
};

type FiltersState<T> = {
  [K in keyof T]: K extends  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    | `${infer _Prefix}Date${infer _Suffix}`
    | `${infer _Prefix}Date`
    | 'date' // Handle date fields first in order not to interfere with strings
    ? { startDate: Date; endDate: Date } | undefined
    : // Handle boolean types that can be boolean or undefined
    T[K] extends boolean | undefined
    ? boolean | undefined
    : // Handle string unions
    T[K] extends string | null
    ? NonNullable<T[K]>[] | undefined
    : // Handle nested objects
    T[K] extends object
    ? string[] | undefined
    : // Default to never for unsupported types
      never;
};

export const useESTableServerFilterControls = <T>(config?: Config) => {
  const { shouldPersist = false, defaultFilters = [] } = config ?? {};
  const [columnFilters, setFilters] = useSyncTableFiltersWithSearchParams(
    shouldPersist,
    defaultFilters
  );

  const filtersState = useMemo(() => {
    return columnFilters.reduce((acc, { id, value }) => {
      return {
        ...acc,
        [id as keyof T]: !Array.isArray(value)
          ? value
          : value.length
          ? value
          : undefined,
      };
    }, {} as FiltersState<T>);
  }, [columnFilters]);

  return { tableColumnFilters: columnFilters, filtersState, setFilters };
};
