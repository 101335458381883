import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery, PageEntry } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { JobModel, JobPageModel } from './jobModel';
import { jobNormalizer, jobsNormalizer } from './normalizers/jobsNormalizer';
import { JobDto } from './jobDto';
import { GetJobsPoolRequestBody } from './getJobsPoolRequestBody';
import { getDataByPlantId, jobStatusMap } from '@energy-stacks/fleet/shared';
import {
  JobsTableBackendSortOrderModel,
  mapJobsTableSortIdToPayload,
} from './mapJobsTableSortIdToPayload';
import {
  OptimizedTourModel,
  toursApi,
} from '@energy-stacks/fleet/feature-tours-data';

const serializeGetJobPoolParameters = (
  searchParams: GetJobsPoolRequestBody
): string => {
  const params = new URLSearchParams();

  Object.entries(searchParams).forEach(([key, value]) => {
    if (!Array.isArray(value)) {
      params.append(key, value);
    } else {
      value.forEach((item) => params.append(key, item));
    }
  });

  return params.toString();
};

export type GetJobsRequestBody = {
  searchValue?: string;
  page: number;
  size: number;
  sort?: JobsTableBackendSortOrderModel;
  statuses?: string[];
  startDateTime?: string;
  endDateTime?: string;
  jobTypes?: string[];
};

type JobPageEntry = PageEntry<JobDto>;

export const jobsApi = createApi({
  reducerPath: 'jobs',
  tagTypes: ['Jobs', 'JobsPool', 'PaginatedJobs'],
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/jobs`),
  endpoints: (builder) => ({
    getJobs: builder.query<JobModel[], void>({
      queryFn: async (_, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          url: '',
          transformResponse: jobsNormalizer,
        });
      },
      providesTags: ['Jobs'],
    }),
    getPaginatedJobs: builder.query<JobPageModel, GetJobsRequestBody>({
      queryFn: async (params, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          url: '/search',
          transformResponse: (jobsPageDto: JobPageEntry) => {
            return {
              totalElements: jobsPageDto.totalElements ?? 0,
              totalPages: jobsPageDto.totalPages ?? 0,
              jobs: jobsNormalizer(jobsPageDto.content ?? []),
              last: jobsPageDto.last ?? false,
              pageable: jobsPageDto.pageable,
            } satisfies JobPageModel;
          },
          params: {
            ...params,
            sort: params?.sort
              ? mapJobsTableSortIdToPayload(params.sort)
              : undefined,
          },
        });
      },
      providesTags: ['PaginatedJobs'],
    }),
    getJobPool: builder.query<JobModel[], GetJobsPoolRequestBody>({
      query: (searchParams) => ({
        url: `/excluding?${serializeGetJobPoolParameters(searchParams)}`,
        method: 'GET',
      }),
      providesTags: ['JobsPool'],
      transformResponse: (jobsDto: JobDto[]) => {
        return jobsDto.map(jobNormalizer);
      },
    }),
    changeJobStatus: builder.mutation<
      void,
      {
        jobId: JobDto['jobUid'];
        status: JobDto['status'];
        tourId: OptimizedTourModel['id'];
      }
    >({
      query: ({ jobId, status }) => ({
        url: `/${jobId}/status`,
        method: 'PATCH',
        body: {
          jobStatus: status,
        },
      }),
      async onQueryStarted(
        { jobId, status, tourId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            toursApi.util.updateQueryData('getTourDetails', tourId, (draft) => {
              for (const job of draft.tourJobs.jobs) {
                if (job.jobId === jobId) {
                  job.status = jobStatusMap[status];
                  break;
                }
              }
            })
          );
        } catch {}
      },
      invalidatesTags: ['Jobs', 'PaginatedJobs'],
    }),
  }),
});

export const {
  useGetJobsQuery,
  useGetJobPoolQuery,
  useChangeJobStatusMutation,
  useGetPaginatedJobsQuery,
  usePrefetch,
} = jobsApi;
