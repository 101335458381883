import { isBoolean } from 'lodash-es';
import { EditVehicleGeneralInfoPayload } from '../editVehicleGeneralInfoPayload';
import { EditVehicleGeneralInfoData } from '../vehiclesApi';

const formDataToPayloadKeysMap: Record<
  keyof EditVehicleGeneralInfoData,
  keyof EditVehicleGeneralInfoPayload
> = {
  coreVehicleId: 'vehicleCoreUuid',
  licencePlate: 'licensePlateNumber',
  name: 'name',
  note: 'notes',
  plantId: 'depotId',
  type: 'ownershipType',
  group: 'usagePriority',
  hasUVFilter: 'skippingPenaltyRules',
};

export const editVehicleGeneralInfoToPayload = (
  data: EditVehicleGeneralInfoData
): EditVehicleGeneralInfoPayload => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const formDataKey = key as keyof EditVehicleGeneralInfoData;
    const formDataValue = typeof value === 'string' ? value.trim() : value;
    return {
      ...acc,
      [formDataToPayloadKeysMap[formDataKey]]:
        formDataValue || isBoolean(formDataValue)
          ? {
              value,
              resetValue: false,
            }
          : {
              resetValue: true,
            },
    };
  }, {} as EditVehicleGeneralInfoPayload);
};
