import { DEFAULT_PAGINATION_CONFIG } from '@energy-stacks/core/ui';
import {
  GetJobsRequestBody,
  useGetPaginatedJobsQuery,
} from '@energy-stacks/fleet/feature-jobs-data';
import { toPayloadDate } from '@energy-stacks/shared';

interface GetJobsConfig {
  search: GetJobsRequestBody['searchValue'];
  pagination: {
    page: GetJobsRequestBody['page'];
    size: GetJobsRequestBody['size'];
  };
  sort: GetJobsRequestBody['sort'];
  startDate: Date | undefined;
  endDate: Date | undefined;
  statuses: GetJobsRequestBody['statuses'];
  jobTypes: GetJobsRequestBody['jobTypes'];
}

export const usePaginatedJobs = (config: GetJobsConfig, skip = false) => {
  return useGetPaginatedJobsQuery(
    {
      searchValue: config?.search ? config.search : undefined,
      page: config?.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
      size: config?.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
      sort: config?.sort,
      startDateTime: config.startDate
        ? toPayloadDate(config.startDate)
        : undefined,
      endDateTime: config.endDate ? toPayloadDate(config.endDate) : undefined,
      statuses: config.statuses ? config.statuses : undefined,
      jobTypes: config.jobTypes ? config.jobTypes : undefined,
    },
    { skip }
  );
};
