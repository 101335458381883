import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery, Pageable } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { businessAccountsNormalizer } from './normalizers/businessAccountsNormalizer';
import { BusinessAccountModel } from './businessAccountModel';
import { businessAccountDetailsNormalizer } from './normalizers/businessAccountDetailsNormalizer';
import { BusinessAccountDetailsModel } from './businessAccountDetailsModel';
import { PlantModel } from './plantModel';
import { plantsNormalizer } from './normalizers/plantsNormalizer';
import { getDataByPlantId } from '@energy-stacks/fleet/shared';
import { BusinessAccountDto } from './businessAccountDto';
import {
  BusinessAccountsTableSortOrderModel,
  mapBusinessAccountsTableSortIdToPayload,
} from './mapBusinessAccountsTableSortIdToPayload';

export type GetBusinessAccountsRequestBody = {
  searchValue?: string;
  page: number;
  size: number;
  sort?: BusinessAccountsTableSortOrderModel;
};

export type BusinessAccountsPageModel = {
  businessAccounts?: BusinessAccountModel[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  pageable: Pageable;
};

export interface BusinessAccountsPageDto {
  totalPages?: number;
  totalElements?: number;
  content?: BusinessAccountDto[];
  last: boolean;
  pageable: Pageable;
}

export const businessAccountsApi = createApi({
  reducerPath: 'business-accounts',
  keepUnusedDataFor: 0,
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/business-accounts`),
  tagTypes: ['BusinessAccounts', 'PaginatedBusinessAccounts'],
  endpoints: (builder) => ({
    getPlants: builder.query<PlantModel[], void>({
      query: () => '/plants',
      transformResponse: plantsNormalizer,
    }),
    getBusinessAccounts: builder.query<BusinessAccountModel[], void>({
      queryFn: async (_, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          url: '',
          transformResponse: businessAccountsNormalizer,
        });
      },
      providesTags: ['BusinessAccounts'],
    }),
    getPaginatedBusinessAccounts: builder.query<
      BusinessAccountsPageModel,
      GetBusinessAccountsRequestBody
    >({
      queryFn: async (params, api, _extraArgs, baseQuery) => {
        return getDataByPlantId({
          api,
          baseQuery,
          url: '/search',
          transformResponse: (
            businessAccountsPageDto: BusinessAccountsPageDto
          ) => {
            return {
              totalElements: businessAccountsPageDto.totalElements ?? 0,
              totalPages: businessAccountsPageDto.totalPages ?? 0,
              businessAccounts: businessAccountsNormalizer(
                businessAccountsPageDto.content ?? []
              ),
              last: businessAccountsPageDto.last,
              pageable: businessAccountsPageDto.pageable,
            } satisfies BusinessAccountsPageModel;
          },
          params: {
            ...params,
            sort: params?.sort
              ? mapBusinessAccountsTableSortIdToPayload(params.sort)
              : undefined,
          },
        });
      },
      providesTags: ['PaginatedBusinessAccounts'],
    }),
    getBusinessAccountDetails: builder.query<
      BusinessAccountDetailsModel,
      string
    >({
      query: (businessAccountId) => `/${businessAccountId}`,
      transformResponse: businessAccountDetailsNormalizer,
    }),
  }),
});

export const {
  useGetPlantsQuery,
  useGetPaginatedBusinessAccountsQuery,
  useGetBusinessAccountsQuery,
  useGetBusinessAccountDetailsQuery,
  usePrefetch,
} = businessAccountsApi;
