import { JobStatus } from '@energy-stacks/fleet/shared';
import { EditingTourJob } from '../edit-tour/editingTourJobModel';
import { TourDetail } from '../tour-details/tourDetail';
import { Row } from '@tanstack/react-table';

export const checkRawJobSequence = <T extends TourDetail | EditingTourJob>(
  rows: Row<T>[],
  currentRowIndex: number
): boolean => {
  const relevantJobStatuses: JobStatus[] = [
    'TO_DO',
    'PENDING',
    'PLANNED',
    'ENROUTE',
    'DONE',
  ];
  const currentJob = rows[currentRowIndex].original;
  const currentJobProductName = currentJob?.product?.name;
  const currentJobStatus = currentJob?.status;

  if (
    !currentJobProductName ||
    currentJobProductName !== 'raw' ||
    (currentJobStatus && !relevantJobStatuses.includes(currentJobStatus))
  ) {
    return false;
  }

  const prevJob =
    currentRowIndex !== 0
      ? rows
          .slice(0, currentRowIndex)
          .reverse()
          .find(
            (row) =>
              row.original.jobType !== 'BREAK' &&
              row.original.jobType !== 'EMPTY_RUN'
          )?.original
      : undefined;
  const prevJobProductName = prevJob?.product?.name;
  const prevJobStatus = prevJob?.status;

  const nextJob =
    currentRowIndex + 1 !== rows.length
      ? rows
          .slice(currentRowIndex + 1)
          .find(
            (row) =>
              row.original.jobType !== 'BREAK' &&
              row.original.jobType !== 'EMPTY_RUN'
          )?.original
      : undefined;
  const nextJobProductName = nextJob?.product?.name;
  const nextJobStatus = nextJob?.status;

  if (!prevJobStatus && !currentJobStatus && !nextJobStatus) {
    // Virtual (wizard) tours (jobs don't have statuses)
    return Boolean(
      (prevJobProductName && currentJobProductName === prevJobProductName) ||
        (nextJobProductName && currentJobProductName === nextJobProductName)
    );
  } else {
    // Tours saved in database (jobs have statuses)
    return Boolean(
      (prevJobStatus &&
        relevantJobStatuses.includes(prevJobStatus) &&
        prevJobProductName &&
        currentJobProductName === prevJobProductName) ||
        (nextJobStatus &&
          relevantJobStatuses.includes(nextJobStatus) &&
          nextJobProductName &&
          currentJobProductName === nextJobProductName)
    );
  }
};
