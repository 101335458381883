import { useMemo } from 'react';
import { usePagination } from './usePagination';
import { useSort } from './useSort';
import { SortingState } from '@tanstack/react-table';
import { useSearch } from './useSearch';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from './defaultRowsPerPageOptions';

type Config = {
  defaultRowsPerPageOptions: number[];
  initialSorting: SortingState;
  shouldPersistState: boolean;
};

export const useESTableServerControls = ({
  defaultRowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  initialSorting,
  shouldPersistState = false,
}: Partial<Config>) => {
  const { pagination, setPagination } = usePagination({
    defaultRowsPerPageOptions,
    shouldPersist: shouldPersistState,
  });

  const { sorting, setSorting, sortOrder, sortColumn } = useSort(
    undefined,
    initialSorting,
    shouldPersistState
  );
  const { search, setSearch } = useSearch(shouldPersistState);

  return useMemo(
    () => ({
      pagination,
      setPagination,
      sorting: {
        state: sorting,
        order: sortOrder,
        column: sortColumn,
      },
      setSorting,
      search,
      setSearch,
    }),
    [
      pagination,
      search,
      setPagination,
      setSearch,
      setSorting,
      sortColumn,
      sortOrder,
      sorting,
    ]
  );
};
